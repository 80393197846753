
import { defineComponent, ref, computed, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { useProfileStore } from '@/store/profile.module';
import { NutritionalPlan, NutritionalPlanCreateFormDto, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { isCreateNutritionalPlanFormValid, createNutritionalPlanForm } from '@/validation/nutritionalPlanForm';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  props: {
    planOnEdit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const storeProfile = useProfileStore();
    const userIsSpecialist = computed(() => storeProfile.isSpecialist);
    const showDialog = ref(false);
    const showValidation = ref(false);
    const submitting = ref(false);
    const nutritionalPlan: Ref<NutritionalPlan> = ref({} as NutritionalPlan);
    const nutritionalPlanCreateFormDto: Ref<NutritionalPlanCreateFormDto> = ref({} as NutritionalPlanCreateFormDto);
    const validatedNutritionalPlanForm = computed(() => createNutritionalPlanForm(nutritionalPlanCreateFormDto.value));
    const valid = computed(() => isCreateNutritionalPlanFormValid(validatedNutritionalPlanForm.value));
    const patientId = route.params.patientId as string;
    const editMode = computed(() => props.planOnEdit);
    const modalLabel = computed(() => (editMode.value ? t('nutrition.edit-plan') : t('nutrition.create-plan')));

    const openDialog = (defaultNutritionalPlan: NutritionalPlan) => {
      if (!defaultNutritionalPlan) {
        return;
      }

      showValidation.value = false;
      showDialog.value = true;
      nutritionalPlan.value = defaultNutritionalPlan;
      nutritionalPlanCreateFormDto.value = new NutritionalPlanCreateFormDto(defaultNutritionalPlan);
    };

    const onSubmit = async (e: Event) => {
      e.preventDefault();
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      try {
        if (editMode.value) {
          await modifyNutritionalPlan(nutritionalPlan.value.id);
        } else {
          await createNutritionalPlan(nutritionalPlanCreateFormDto.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    async function createNutritionalPlan(createdPlan: NutritionalPlanCreateFormDto) {
      submitting.value = true;
      const result = await nutritionalPlanService.create(patientId, createdPlan);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateNutritionalPlan')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successCreateNutritionalPlan')}`,
          life: 3000,
        });
        showDialog.value = false;
        router.push(`/patient/${patientId}/nutrition/plans/${result.id}/patient-data`);
      }
    }

    async function modifyNutritionalPlan(id: string) {
      submitting.value = true;

      nutritionalPlan.value.name = nutritionalPlanCreateFormDto.value.name;
      const result = await nutritionalPlanService.update(
        patientId,
        id,
        new NutritionalPlanFormDto(nutritionalPlan.value),
      );
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateNutritionalPlan')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successCreateNutritionalPlan')}`,
          life: 3000,
        });
        showDialog.value = false;
      }
    }
    return {
      showValidation,
      submitting,
      showDialog,
      userIsSpecialist,
      validatedNutritionalPlanForm,
      nutritionalPlanCreateFormDto,
      openDialog,
      onSubmit,
      editMode,
      modalLabel,
    };
  },
});
