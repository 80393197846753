
import dateFormat from '@/helpers/DateFormat.helper';
import { MacroType, NutritionalPlan, NutritionalPlanFormDto } from '@/models/NutritionalPlan';
import { useProfileStore } from '@/store/profile.module';
import 'chartjs-adapter-moment';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import HealzButton from '../../healz/ui/HealzButton.vue';
import EventBus from '../../../event-bus';

export default defineComponent({
  components: { HealzButton },
  props: {
    nutritionalPlan: {
      type: Object as PropType<NutritionalPlan>,
      required: true,
    },
  },
  setup(props) {
    const nutritionalPlanDeleteModalRef = ref();
    const route = useRoute();
    const router = useRouter();
    const storeProfile = useProfileStore();
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);
    const proteinBites = computed(() => getBites(MacroType.Protein));
    const fatBites = computed(() => getBites(MacroType.Fat));
    const carbsBites = computed(() => getBites(MacroType.Carbs));

    const getBites = (macroType: MacroType) => {
      let total = 0;
      if (props.nutritionalPlan?.meals?.length > 0) {
        props.nutritionalPlan.meals.forEach((meal) => {
          meal.mealDetails.forEach((mealDetails) => {
            if (mealDetails.macroType === macroType) {
              total += mealDetails.bites;
            }
          });
        });
      }
      return total;
    };

    const editMode = ref(false);
    const waitSubmit = ref(false);
    const patientId = route.params.patientId as string;

    const nutritionalPlanFormDto = ref<NutritionalPlanFormDto>(new NutritionalPlanFormDto(props.nutritionalPlan));

    const onViewNutritionalPlan = () => {
      router.push(`/patient/${patientId}/nutrition/plans/${props.nutritionalPlan.id}/patient-data`);
    };

    const onUpdateNutritionalPlan = () => {
      EventBus.emit('on-edit-nutritional-plan', props.nutritionalPlan);
    };

    function userCanEditNutritionalPlan() {
      return isAdminUser.value || isReceptionistUser.value || isSpecialistUser.value;
    }

    const nutritionalPlanEnabledRef = ref(nutritionalPlanFormDto.value.enabled);

    watch(nutritionalPlanEnabledRef, (newValue) => {
      EventBus.emit('on-nutritional-plan-enabled-changed', {
        updatedPlan: props.nutritionalPlan,
        enabled: newValue,
      });
    });

    return {
      dateFormat,
      nutritionalPlanFormDto,
      nutritionalPlanDeleteModalRef,
      editMode,
      waitSubmit,
      userCanEditNutritionalPlan,
      onUpdateNutritionalPlan,
      onViewNutritionalPlan,
      proteinBites,
      fatBites,
      carbsBites,
      nutritionalPlanEnabledRef,
    };
  },
});
