import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_NutritionalPlansList = _resolveComponent("NutritionalPlansList")!
  const _component_NutritionalPlanCreateFormModal = _resolveComponent("NutritionalPlanCreateFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-nutrition"
    }, {
      center: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onNewNutritionPlan
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('nutrition.create-plan')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          onClick: _ctx.onNewNutritionPlan
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('nutrition.create-plan')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("main", _hoisted_1, [
      _withDirectives(_createVNode(_component_LoadingComponent, { "spinner-color": "lavender-700" }, null, 512), [
        [_vShow, _ctx.loadingNutritionalPlans]
      ]),
      (!_ctx.loadingNutritionalPlans)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_NutritionalPlansList, { nutritionalPlans: _ctx.nutritionalPlans }, null, 8, ["nutritionalPlans"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NutritionalPlanCreateFormModal, {
      ref: "newNutritionPlanModalRef",
      planOnEdit: _ctx.isEditModal,
      nutritionalPlanList: _ctx.nutritionalPlans,
      "patient-id": _ctx.patientId,
      onPlansUpdated: _ctx.onNutritionalPlansUpdated
    }, null, 8, ["planOnEdit", "nutritionalPlanList", "patient-id", "onPlansUpdated"])
  ], 64))
}