import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "nutritional-plan-container" }
const _hoisted_3 = { class: "nutritional-plan-macros" }
const _hoisted_4 = { class: "info-item" }
const _hoisted_5 = { class: "info-item" }
const _hoisted_6 = { class: "info-item" }
const _hoisted_7 = { class: "info-item" }
const _hoisted_8 = { class: "edit-buttons" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HealzButton = _resolveComponent("HealzButton")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "nutritional-plan-info",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onViewNutritionalPlan && _ctx.onViewNutritionalPlan(...args)))
      }, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.nutritionalPlan.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("i", {
              class: "icon-meals",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.nutritionalPlan?.meals?.length || '--'), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("i", {
              class: "icon-proteins",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.proteinBites || '--'), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[4] || (_cache[4] = _createElementVNode("i", {
              class: "icon-fats",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.fatBites || '--'), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("i", {
              class: "icon-hc",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.carbsBites || '--'), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives((_openBlock(), _createBlock(_component_HealzButton, {
          disabled: !_ctx.userCanEditNutritionalPlan(),
          class: "p-button-table",
          onClickStop: _ctx.onViewNutritionalPlan
        }, {
          content: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-show",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_9)
          ]),
          _: 1
        }, 8, ["disabled", "onClickStop"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_HealzButton, {
          disabled: !_ctx.userCanEditNutritionalPlan(),
          class: "p-button-table",
          onClickStop: _ctx.onUpdateNutritionalPlan
        }, {
          content: _withCtx(() => [
            _createElementVNode("i", {
              class: "icon-edit",
              "aria-label": _ctx.$t('common.edit'),
              "aria-hidden": "true"
            }, null, 8, _hoisted_10)
          ]),
          _: 1
        }, 8, ["disabled", "onClickStop"])), [
          [
            _directive_tooltip,
            _ctx.$t('common.edit'),
            void 0,
            { bottom: true }
          ]
        ]),
        _createVNode(_component_InputSwitch, {
          modelValue: _ctx.nutritionalPlanEnabledRef,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nutritionalPlanEnabledRef) = $event)),
          inputId: "switchEnabled",
          disabled: !_ctx.userCanEditNutritionalPlan()
        }, null, 8, ["modelValue", "disabled"])
      ])
    ])
  ]))
}