
import dateFormat from '@/helpers/DateFormat.helper';
import { NutritionalPlan } from '@/models/NutritionalPlan';
import { Patient } from '@/models/Patient';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { useToast } from 'primevue/usetoast';
import { computed, defineComponent, onMounted, onUnmounted, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import EventBus from '../../../event-bus';
import HeadingComponent from '../../HeadingComponent.vue';
import LoadingComponent from '../../LoadingComponent.vue';
import NutritionalPlanCreateFormModal from './NutritionalPlanCreateFormModal.vue';
import NutritionalPlansList from './NutritionalPlansList.vue';

export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    NutritionalPlanCreateFormModal,
    NutritionalPlansList,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const nutritionalPlans: Ref<NutritionalPlan[] | undefined> = ref();
    const newNutritionPlanModalRef = ref();
    const loadingNutritionalPlans = ref(false);
    const patientId = computed(() => props.patient.id);
    const isEditModal = ref(false);
    const toast = useToast();
    const { t } = useI18n();

    const fetchNutritionPlan = async () => {
      loadingNutritionalPlans.value = true;
      const result = await nutritionalPlanService.findAll(patientId.value);
      if (!(result instanceof ServiceError)) {
        const sortedPlans = result
          .slice()
          .sort(
            (a, b) =>
              dateFormat.getTimeFromDateString(b.creationDate) - dateFormat.getTimeFromDateString(a.creationDate),
          );

        nutritionalPlans.value = [...sortedPlans];
      }
      loadingNutritionalPlans.value = false;
    };

    onMounted(async () => {
      await fetchNutritionPlan();
      EventBus.on('on-edit-nutritional-plan', onEditNutritionPlan);
      EventBus.on('on-nutritional-plan-enabled-changed', onNutritionalPlanEnabledChanged);
    });

    onUnmounted(() => {
      EventBus.off('on-edit-nutritional-plan', onEditNutritionPlan);
      EventBus.off('on-nutritional-plan-enabled-changed', onNutritionalPlanEnabledChanged);
    });

    const onNutritionPlanUpdated = async () => {
      await fetchNutritionPlan();
    };

    const onNewNutritionPlan = async () => {
      isEditModal.value = false;
      const newNutritionalPlan = {} as NutritionalPlan;
      newNutritionPlanModalRef.value?.openDialog(newNutritionalPlan);
    };

    const onEditNutritionPlan = async (nutritionalPlanSelected: NutritionalPlan) => {
      isEditModal.value = true;
      newNutritionPlanModalRef.value?.openDialog(nutritionalPlanSelected);
    };

    const onNutritionalPlansUpdated = async () => {
      await fetchNutritionPlan();
    };

    const onNutritionalPlanEnabledChanged = async (updatedPlanData: any) => {
      if (!updatedPlanData && !updatedPlanData.updatedPlan) {
        return;
      }
      updatedPlanData.updatedPlan.enabled = updatedPlanData.updatedPlan.enabled ? updatedPlanData.enabled : true;
      const result = await nutritionalPlanService.updateEnabled(
        props.patient.id,
        updatedPlanData.updatedPlan.id,
        updatedPlanData.updatedPlan.enabled,
      );
      await fetchNutritionPlan();
      if (!(result instanceof ServiceError)) {
        toast.add({
          severity: 'success',
          summary: `${t('messages.notifications.successEditNutritionPlan')}`,
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditNutritionalPlan')}`,
          life: 3000,
        });
      }
    };

    return {
      nutritionalPlans,
      loadingNutritionalPlans,
      route,
      newNutritionPlanModalRef,
      fetchNutritionPlan,
      onNutritionPlanUpdated,
      onNewNutritionPlan,
      onEditNutritionPlan,
      patientId,
      isEditModal,
      onNutritionalPlansUpdated,
    };
  },
});
