<template>
  <div>
    <div v-if="nutritionalPlans && nutritionalPlans.length > 0" class="c-nutrition-container">
      <NutritionalPlanCard v-for="plan in nutritionalPlans" :key="plan.id" :nutritionalPlan="plan" />
    </div>
    <div v-else class="c-nutrition-empty-state-container">
      <EmptyState
        :heading="$t('nutrition.isEmptyTitle')"
        :text="$t('nutrition.isEmptyMessage')"
        :secondary="false"
        icon="icon-file_blank_outline"
      ></EmptyState>
    </div>
  </div>
</template>

<script>
import EmptyState from '@/components/EmptyState.vue';
import NutritionalPlanCard from './NutritionalPlanCard.vue';

export default {
  components: {
    NutritionalPlanCard,
    EmptyState,
  },
  props: {
    nutritionalPlans: Array,
  },
};
</script>

<style scoped>
.c-nutrition-empty-state-container {
  background-color: white;
  padding: 20px;
}

.c-nutrition-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
