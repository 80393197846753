import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_2 = { for: "name" }
const _hoisted_3 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.modalLabel,
    modal: true,
    class: "p-fluid"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.editMode ? _ctx.$t('common.refresh') : _ctx.$t('common.add'),
        class: "p-button p-component p-button-info p-button-medium",
        loading: _ctx.submitting,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.editMode ? _ctx.$t('nutrition.edit-name') : _ctx.$t('nutrition.add-name')), 1),
      _createElementVNode("form", {
        class: "p-grid",
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('nutrition.name')) + "*", 1),
          _createVNode(_component_InputText, {
            id: "name",
            modelValue: _ctx.nutritionalPlanCreateFormDto.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nutritionalPlanCreateFormDto.name) = $event)),
            modelModifiers: { trim: true },
            required: "true",
            class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validatedNutritionalPlanForm.name.valid })
          }, null, 8, ["modelValue", "class"]),
          (_ctx.showValidation && !_ctx.validatedNutritionalPlanForm.name.valid)
            ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.$t(`${_ctx.validatedNutritionalPlanForm.name.message}`)), 1))
            : _createCommentVNode("", true)
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}